import React, { useEffect } from 'react';
import { bool, string, shape, arrayOf, func, oneOfType, oneOf, number } from 'prop-types';
import classnames from 'classnames';
import Snackbar from '@andes/snackbar';
import IconFactory from '../../icons/factory-builder';
import Message from '../../message';
import StyledLabel from '../../styled-label';
import StatusInfo from './status-info';
import ProfileInfo from './profile-info';
import Reputation from '../../seller-info-label/components/reputation';
import { validateSnackbarColor } from '../../../utils/validators';
import { familynames, sizenames, colornames } from '@vpp-frontend-components/common';
import LinkLabel from './link-label';

const DEFAULT_DELAY_TIME = 3000;

const namespace = 'ui-vip-seller-profile';

const ContainerIconUserStatus = ({ icon }) => (
  <div aria-hidden="true" className="ui-vip-icon-status__container">
    {icon && IconFactory(icon)}
  </div>
);

ContainerIconUserStatus.propTypes = {
  icon: oneOfType([shape, string]),
};

ContainerIconUserStatus.defaultProps = {
  icon: null,
};

const Container = ({
  phoneLink,
  thermometer,
  tagsReputationAlign,
  extraInfo,
  sellerName,
  fetchContactInfo,
  header,
  statusInfoVisProps,
  userStatusTooltip,
  messages,
  featureName,
  focus,
  onScrollComponent,
  bottomExtraInfo,
  linkLabel,
  recaptchaSiteKey,
  redirectToLogin,
  withBottomSeparatorBar,
  withBottomLinkLabel,
  withTagsSeparatorBar,
  className,
}) => {
  const ref = React.useRef();
  const { rank, default_info: defaultInfo, info } = thermometer || {};

  useEffect(() => {
    if (focus) {
      ref.current.scrollIntoView({ behavior: 'smooth', inline: 'center' });
      setTimeout(() => {
        onScrollComponent(false);
      }, 1000);
    }
  });

  return (
    <div ref={ref} className={classnames(namespace, className, 'ui-box-component')} id={featureName}>
      <StyledLabel as="h2" {...header} className={`${namespace}__header mb-24`} />
      <ProfileInfo {...sellerName} userStatusIcon={sellerName.user_status_icon} />
      {userStatusTooltip && (
        <Message className={`${namespace}__user-status`} closeable={userStatusTooltip.closeable}>
          <div className={`${namespace}__message-container`}>
            <StyledLabel {...userStatusTooltip.body} />
          </div>
        </Message>
      )}
      {extraInfo && extraInfo.length > 0 && (
        <ul className={`${namespace}__list-extra-info`}>
          {extraInfo.map(extinfo => (
            <StatusInfo
              key={extinfo.title.text}
              icon={IconFactory(extinfo.icon)}
              iconClassName={classnames(colornames(extinfo.icon), 'ui-seller-info__status-row')}
              title={extinfo.title.text}
              titleClassName={classnames(colornames(extinfo.title), familynames(extinfo.title), `${namespace}__title`)}
              subtitles={extinfo.subtitles}
              actionLink={extinfo.action_dto}
              fetchContactInfo={fetchContactInfo}
              redirectToLogin={redirectToLogin}
              recaptchaSiteKey={recaptchaSiteKey}
              featureName={featureName}
              contactType="show-phones"
              textWithAction={extinfo.text_with_action}
              {...statusInfoVisProps}
            />
          ))}
        </ul>
      )}
      {thermometer && (
        <Reputation thermometer={{ rank, defaultInfo, info, tagsReputationAlign, withTagsSeparatorBar }} />
      )}
      {phoneLink && (
        <ul className={`${namespace}__list-extra-info`}>
          <StatusInfo
            titleClassName={`${namespace}__title`}
            actionLink={phoneLink}
            fetchContactInfo={fetchContactInfo}
            redirectToLogin={redirectToLogin}
            recaptchaSiteKey={recaptchaSiteKey}
            featureName={featureName}
            contactType="show-phones"
            {...statusInfoVisProps}
          />
        </ul>
      )}
      {messages && messages.text && (
        <Snackbar
          key={messages.key}
          delay={DEFAULT_DELAY_TIME}
          message={messages.text}
          color={validateSnackbarColor(messages.type)}
          show
        />
      )}
      {linkLabel && !withBottomLinkLabel && (
        <LinkLabel linkLabel={linkLabel} className={`${namespace}__property-link`} />
      )}
      {bottomExtraInfo && (
        <>
          {withBottomSeparatorBar && <hr className={`${namespace}__divider`} />}
          <ul className={`${namespace}__list-extra-info`}>
            {bottomExtraInfo.map(extinfo => (
              <StatusInfo
                key={extinfo.title.text}
                icon={IconFactory(extinfo.icon)}
                iconClassName={classnames(colornames(extinfo.icon), 'ui-seller-info__status-row')}
                title={extinfo.title.text}
                titleClassName={classnames(
                  colornames(extinfo.title),
                  familynames(extinfo.title),
                  sizenames(extinfo.title),
                  `${namespace}__title`,
                )}
                subtitles={extinfo.subtitles}
                actionLink={extinfo.action_dto}
                recaptchaSiteKey={recaptchaSiteKey}
              />
            ))}
          </ul>
        </>
      )}
      {linkLabel && withBottomLinkLabel && (
        <LinkLabel linkLabel={linkLabel} className={`${namespace}__property-link`} />
      )}
    </div>
  );
};

Container.propTypes = {
  extraInfo: arrayOf(
    shape({
      icon: shape({ id: string, color: string }),
      subtitles: arrayOf(shape({ text: string })),
      title: shape({ text: string }),
      action_dto: shape({
        label: shape({
          text: string,
        }),
        track: shape({}),
      }),
    }),
  ),
  phoneLink: shape({
    label: shape({
      text: string,
    }),
    track: shape({}),
    action_type: string,
  }),
  header: shape({
    text: string,
    color: string,
    font_size: string,
    font_family: string,
  }).isRequired,
  sellerName: shape({
    title: shape({
      text: string,
      color: string,
      font_size: string,
      font_family: string,
    }),
    subtitle: shape({
      text: string,
      color: string,
      font_size: string,
      font_family: string,
    }),
    user_status_icon: shape({
      id: string,
    }),
    logo: shape({
      alt: string,
      url: shape({
        src: string,
      }),
      width: number,
      height: number,
    }),
    href: string,
  }).isRequired,
  userStatusTooltip: shape({
    body: shape({
      text: string,
      color: string,
    }),
    closeable: bool,
    thumbnail: shape({
      id: string,
    }),
  }),
  fetchContactInfo: func,
  statusInfoVisProps: shape({
    itemId: string,
    isFetching: bool,
  }),
  containerActions: arrayOf({
    actions: arrayOf(
      shape({
        label: shape({
          text: string.isRequired,
          color: string,
        }).isRequired,
        type: string.isRequired,
        target: string,
        track: shape({}),
      }),
    ).isRequired,
  }),
  recaptchaPublicKey: string,
  recaptchaSiteKey: string,
  messages: shape({
    text: string,
    type: oneOf(['success', 'error']),
    key: number,
  }),
  focus: bool,
  doContactAction: arrayOf(
    shape({
      origin: string,
    }),
  ),
  redirectToLogin: func,
  onScrollComponent: func,
  featureName: string.isRequired,
  bottomExtraInfo: arrayOf(
    shape({
      icon: shape({
        id: string,
        color: string,
        size: string,
      }),
      title: shape({
        text: string,
        color: string,
        font_size: string,
        font_family: string,
      }),
      subtitles: arrayOf(
        shape({
          text: string,
          color: string,
          font_size: string,
          font_family: string,
        }),
      ),
    }),
  ),
  linkLabel: shape({
    target: string,
    html_target: string,
    action_type: string,
    label: shape({
      text: string,
      color: string,
      font_size: string,
      font_family: string,
    }),
    track: shape({
      analytics_event: {
        page: string,
        action: string,
        category: string,
        label: string,
      },
    }),
  }),
  withBottomSeparatorBar: bool,
  withBottomLinkLabel: bool,
  withTagsSeparatorBar: bool,
  thermometer: shape({
    rank: number,
    info: arrayOf(
      shape({
        icon: shape({ id: string }),
        subtitle: string,
        title: string,
      }),
    ),
    defaultInfo: shape({
      subtitle: shape({
        color: string,
        text: string,
      }),
      title: shape({
        color: string,
        text: string,
      }),
    }),
  }),
  tagsReputationAlign: string,
  layout: string.isRequired,
  className: string,
};

Container.defaultProps = {
  thermometer: null,
  phoneLink: null,
  extraInfo: null,
  userStatusTooltip: null,
  fetchContactInfo: null,
  statusInfoVisProps: null,
  containerActions: null,
  recaptchaPublicKey: null,
  recaptchaSiteKey: null,
  messages: null,
  focus: false,
  redirectToLogin: null,
  doContactAction: null,
  onScrollComponent: null,
  bottomExtraInfo: null,
  linkLabel: null,
  tagsReputationAlign: null,
  withBottomSeparatorBar: true,
  withBottomLinkLabel: false,
  withTagsSeparatorBar: false,
  className: '',
};

export default React.memo(Container);
