import React from 'react';
import { shape, string } from 'prop-types';
import MediaAction from '../../media/media-action';
import StyledLabel from '../../styled-label';

const LinkLabel = ({ linkLabel, className }) => (
  <span className={className}>
    {linkLabel.label.text && <StyledLabel {...linkLabel.label} text={linkLabel.label.text} as="span" />}
    <MediaAction label={linkLabel.label.values.blue_text} target={linkLabel.target} htmlTarget={linkLabel.htmlTarget} />
  </span>
);

LinkLabel.propTypes = {
  className: string,
  linkLabel: shape({
    target: string,
    html_target: string,
    action_type: string,
    label: shape({
      text: string,
      color: string,
      font_size: string,
      font_family: string,
    }),
    track: shape({
      analytics_event: shape({
        page: string,
        action: string,
        category: string,
        label: string,
      }),
    }),
  }),
};

LinkLabel.defaultProps = {
  className: '',
  linkLabel: null,
};

export default LinkLabel;
