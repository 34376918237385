import React, { useEffect, useState } from 'react';
import { string, shape, arrayOf, object } from 'prop-types';
import { Button } from '@andes/button';
import ThumbUpOutlined24 from '@andes/icons/ThumbUpOutlined24';
import ThumbUpDownlined24 from '@andes/icons/ThumbDownOutlined24';
import FeedbackPositive24 from '@andes/icons/FeedbackPositive24';
import StyledLabel from '../styled-label';
import { trackEvent } from '../../lib/tracking';
import { useLocalStorage } from '../../hooks/use-local-storage';

const availableIcon = {
  THUMB_UP: <ThumbUpOutlined24 />,
  THUMB_DOWN: <ThumbUpDownlined24 />,
};

const namespace = 'ui-pdp-feedback-block-action';

const FeatureFeedback = ({ title, actions, local_storage_key }) => {
  const [storedFeedback, setStoredFeedback, localStorageSupported] = useLocalStorage(local_storage_key);
  const [congratsMessage, setCongratsMessage] = useState();
  const [enabledAddNewFeedback, setEnabledAddNewFeedback] = useState(false);

  useEffect(() => {
    setEnabledAddNewFeedback(localStorageSupported && !storedFeedback);
  }, [localStorageSupported, storedFeedback]);

  const selectAction = action => {
    setStoredFeedback(action.id);
    setCongratsMessage(action.congrats.text);
    trackEvent(action.track);
  };

  if (!enabledAddNewFeedback && !congratsMessage) {
    return null;
  }

  return (
    <div className={`${namespace}__wrapper-block`}>
      {congratsMessage ? (
        <div className={`${namespace}__feedback-message`}>
          <FeedbackPositive24 />
          <StyledLabel text={congratsMessage} />
        </div>
      ) : (
        <>
          <div className={`${namespace}__feedback-heading`}>
            <StyledLabel className={`${namespace}__feedback-block-title`} text={title.text} />
          </div>
          <div className={`${namespace}__feedback-actions`}>
            {actions.map(action => (
              <Button
                key={action.id}
                data-testid={`action-id-${action.id}`}
                onClick={() => selectAction(action)}
                size="medium"
                className={`${namespace}__feedback-btn`}
                hierarchy="transparent"
              >
                {availableIcon[action.icon]}
                {action.text}
              </Button>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

FeatureFeedback.propTypes = {
  local_storage_key: string.isRequired,
  title: shape({
    text: string,
  }).isRequired,
  actions: arrayOf(
    shape({
      id: string.isRequired,
      icon: string.isRequired,
      text: string.isRequired,
      congrats: shape({
        text: string.isRequired,
      }),
      // eslint-disable-next-line react/forbid-prop-types
      track: object,
    }).isRequired,
  ).isRequired,
};

export default FeatureFeedback;
