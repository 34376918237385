import React from 'react';
import { shape } from 'prop-types';
import SellerProfileContainer from './components/container.desktop';
import useTrackView from '../../hooks/use-track-view';

const SellerProfile = ({ show_track, ...props }) => {
  const refView = useTrackView(show_track, { threshold: 0.3 });

  return (
    <div ref={refView}>
      <SellerProfileContainer className="ui-vip-seller-profile" {...props} />
    </div>
  );
};

SellerProfile.propTypes = {
  show_track: shape({}),
};

SellerProfile.defaultProps = {
  show_track: null,
};

export default React.memo(SellerProfile);
