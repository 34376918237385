import React, { useState, useContext, useRef } from 'react';
import { string, arrayOf, shape, number } from 'prop-types';
import Lightbox from '../lightbox';
import StyledLabel from '../styled-label';
import Image from '../image';
import ActionsBuybox from '../buybox/actions/actions-buybox';
import MediaAction from '../media/media-action';
import StaticPropsContext from '../context/static-props';
import { GalleryProvider } from '../gallery/context/gallery.contex';

const namespace = 'ui-vip-available-units';

const DEFAULT_LIGHTBOX_SETTINGS = {
  thumbnailClassname: `.${namespace}__figure img`,
  showAnimationDuration: 1,
  showHideOpacity: true,
  barsSize: {
    top: 66,
    bottom: 66,
  },
};

const AvailableUnits = ({ title, content_rows, actions, init_row_size, itemId }) => {
  const unitsWrapper = useRef(null);
  const { deviceType } = useContext(StaticPropsContext);
  const isDesktop = deviceType === 'desktop';

  const [seeMore, setSeeMore] = useState(false);
  const [lightboxPictures, setLightboxPictures] = useState(null);

  let counter = 0;
  const visibleHandler = e => {
    e.preventDefault();
    counter += 1;
    const unitContainer = Array.from(document.getElementsByClassName(`row-${counter}`));
    unitContainer.map(value => value.classList.add('visible'));
    const lastElements = unitContainer.length < init_row_size;
    if (lastElements) {
      setSeeMore(true);
    }
  };

  const hiddenHandler = e => {
    e.preventDefault();
    const visibleContainer = Array.from(document.querySelectorAll('.visible'));
    visibleContainer.map(value => value.classList.remove('visible'));
    setSeeMore(false);
    window.scrollTo({
      left: 0,
      top: unitsWrapper.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const onClickHandler = seeMore ? hiddenHandler : visibleHandler;

  const lightboxProps = {
    pictures: lightboxPictures,
  };

  return (
    <div className={`${namespace}__container`} ref={unitsWrapper}>
      <StyledLabel as="h2" {...title} className={`${namespace}__title`} />
      <div>
        {content_rows.map((value, index) => (
          <div
            className={`${namespace}__unit-container row-${Math.floor(index / (isDesktop ? init_row_size : 3))}`}
            key={value.upper_label}
          >
            <div
              className={`${namespace}__img-container`}
              role="button"
              tabIndex="0"
              aria-hidden="true"
              data-testid="gallery-img"
              onClick={() => setLightboxPictures(value.pictures)}
            >
              <Image alt="" src={value.pictures[0].thumbnail.src2x} lazyload />
            </div>
            <div className={`${namespace}__unit-info-container`}>
              <div className={`${namespace}__unit-info`}>
                <StyledLabel as="p" {...value.upper_label} />
                <StyledLabel as="h3" {...value.title} />
                <StyledLabel as="p" {...value.subtitle} />
              </div>
              {value.action && (
                <div className={`${namespace}__action-container`}>
                  <ActionsBuybox actions={[value.action]} itemId={itemId} />
                </div>
              )}
            </div>
          </div>
        ))}
        {actions && content_rows.length > init_row_size && (
          <div className={`${namespace}__see-more`}>
            <MediaAction
              {...(seeMore ? actions[1] : actions[0])}
              onClick={onClickHandler}
              className={seeMore ? 'chevron-down' : 'chevron-active'}
            />
          </div>
        )}
        {/* istanbul ignore next */
        lightboxPictures !== null && (
          <GalleryProvider {...lightboxProps}>
            <Lightbox
              index={0}
              pictures={lightboxPictures}
              settings={DEFAULT_LIGHTBOX_SETTINGS}
              onClose={() => setLightboxPictures(null)}
            />
          </GalleryProvider>
        )}
      </div>
    </div>
  );
};

AvailableUnits.propTypes = {
  title: string.isRequired,
  itemId: string.isRequired,
  content_rows: arrayOf(
    shape({
      upper_label: shape({
        text: string,
        color: string,
        font_size: string,
        font_family: string,
      }),
      title: shape({
        text: string,
        color: string,
        font_size: string,
        font_family: string,
      }),
      subtitle: shape({
        text: string,
        color: string,
        font_size: string,
        font_family: string,
      }),
      pictures: arrayOf(
        shape({
          id: string,
          alt: string,
          width: number,
          height: number,
          url: string,
        }),
      ),
    }),
  ).isRequired,
  init_row_size: number,
  actions: arrayOf(
    shape({
      type: string,
      label: shape({
        text: string,
        color: string,
      }),
      track: shape({}),
    }),
  ),
};

AvailableUnits.defaultProps = {
  actions: null,
  init_row_size: null,
};

export default React.memo(AvailableUnits);
