import React, { useEffect } from 'react';
import { shape, string, func, bool } from 'prop-types';
import StyledLabel from '../../../styled-label';
import { Form } from '@andes/form';
import { AndesProvider } from '@andes/context';
import { Button, ProgressButton } from '@andes/button';
import componentEnhance from '../../../../lib/component-enhance';
import { Recaptchav2 } from '../../../recaptcha';
import IconFactory from '../../../icons/factory-builder';
import { trackEvent } from '../../../../lib/tracking';

const UnregisteredPreLogin = ({
  title,
  subtitle,
  namespace,
  primary_action,
  secondary_action,
  showUnregistered,
  replaceText,
  selection_info,
  selectedUnit,
  selectedModel,
  stepBack,
  isUnregistered,
  handleOnSubmit,
  url,
  recaptchaPublicKey,
  setRecaptchaTokenV2,
  isPostingQuotation,
}) => {
  const unitData = selectedModel?.picture?.alt ? selectedModel?.picture?.alt : selectedModel?.decorated_name?.text;

  useEffect(() => {
    // Este código es necesario para hacer el resize en el experimento y ajustar el wrapper según el step
    const modal = document.querySelector(`.${namespace}`);
    const modalWrapper = document.querySelector('.ui-vip-request-modal__modal');
    const carrouselModalWrapper = document.querySelector('.ui-vip-models-carousel__modal.andes-modal--large');
    const modalHeight = { label: '--min-modal-height', value: '300px' };

    if (modal && modalWrapper) {
      if (isUnregistered) {
        modal.classList.remove('minimize');
        modalWrapper.style.setProperty(modalHeight.label, modalHeight.value);
      } else {
        modal.classList.add('minimize');
        carrouselModalWrapper?.style.setProperty(modalHeight.label, modalHeight.value);
      }
    }
  }, [isUnregistered, namespace]);

  return (
    <div className={namespace}>
      <StyledLabel className={`${namespace}__title`} {...title} text={componentEnhance.jsx(title.text, replaceText)} />
      <StyledLabel
        className={`${namespace}__subtitles`}
        key={subtitle.text}
        as="p"
        text={componentEnhance.jsx(subtitle.text, subtitle.attribute_values)}
      />
      {replaceText && isUnregistered && (
        <div className={`${namespace}__collapsible-info`}>
          <div className={`${namespace}__collapsible-info__container`}>
            <StyledLabel as="p" className={`${namespace}__collapsible-info-title`} {...selection_info.title} />
            <div className={`${namespace}__collapsible-info-flex`}>
              <span className={`${namespace}__collapsible-info-unit`}>
                {`Unidad ${selectedUnit.name.text} - ${unitData}`}
              </span>
              <div className={`${namespace}__collapsible-info__edit`}>
                {selection_info.collapsible_info.icon ? (
                  <StyledLabel
                    className={`${namespace}__collapsible-info__edit`}
                    as="p"
                    onClick={stepBack}
                    text={IconFactory(selection_info.collapsible_info.icon.id)}
                  />
                ) : (
                  <StyledLabel
                    {...selection_info.collapsible_info.edit_link}
                    className={`${namespace}__collapsible-info__edit`}
                    as="a"
                    onClick={stepBack}
                    data-testid="edit_link"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {recaptchaPublicKey && (
        <Recaptchav2
          align="left"
          className={`${namespace}-recaptcha`}
          recaptchaPublicKey={recaptchaPublicKey}
          required
          lowEnd
          id="unregistered-quotation-recaptcha"
          verifyCallback={token => setRecaptchaTokenV2(token)}
        />
      )}

      <div className={`${namespace}__actions`}>
        {isUnregistered ? (
          <AndesProvider>
            <Form className={`${namespace}__actions__form`} action={url} method="POST" onSubmit={handleOnSubmit}>
              <ProgressButton
                loading={isPostingQuotation}
                progressLabel={primary_action.label.text}
                className={`${namespace}__actions__send`}
                onClick={() => trackEvent(primary_action?.track)}
                type="submit"
              >
                {primary_action.label.text}
              </ProgressButton>
            </Form>
          </AndesProvider>
        ) : (
          <Button
            className={`${namespace}__actions__login`}
            onClick={() => trackEvent(primary_action.track)}
            href={primary_action.target}
          >
            {primary_action.label.text}
          </Button>
        )}
        <Button
          hierarchy="transparent"
          className={`${namespace}__actions__continue`}
          onClick={() => showUnregistered()}
        >
          {`${secondary_action.label.text} ${isUnregistered ? replaceText.name.label.text : ''}`}
        </Button>
      </div>
    </div>
  );
};

UnregisteredPreLogin.propTypes = {
  onSubmit: func.isRequired,
  recaptchaPublicKey: string,
  url: string,
  isPostingQuotation: bool,
  selectedModel: shape({}),
  selectedUnit: shape({
    name: shape({
      text: string,
    }),
  }),
  selection_info: shape({
    title: shape({
      text: string,
    }),
    collapsible_info: shape({
      icon: shape({
        id: string,
      }),
    }),
  }),
  title: shape({
    text: string,
  }),
  subtitle: shape({
    text: string,
  }),
  namespace: string,
  primary_action: shape({
    label: shape({
      text: string,
    }),
    track: shape({}),
  }),
  secondary_action: shape({
    label: shape({
      text: string,
    }),
  }),
  stepBack: func,
  isUnregistered: bool,
  showUnregistered: string,
  setRecaptchaTokenV2: func,
  handleOnSubmit: func,
  replaceText: shape({}),
};

UnregisteredPreLogin.defaultProps = {
  className: null,
  preview_form: null,
  messages: null,
  recaptchaPublicKey: null,
  url: null,
  isPostingQuotation: false,
  stepBack: null,
  selectedModel: null,
  selectedUnit: null,
  selection_info: null,
};

export default UnregisteredPreLogin;
