import React, { useState, useEffect, useContext } from 'react';
import { arrayOf, bool, shape, string, func, oneOf, number, oneOfType } from 'prop-types';
import classnames from 'classnames';
import { Form } from '@andes/form';
import { AndesProvider } from '@andes/context';
import { Button } from '@andes/button';
import { TextField } from '@andes/textfield';
import { Checkbox } from '@andes/checkbox';
import { Snackbar } from '@andes/snackbar';
import StyledLabel from '../styled-label';
import Media from '../media';
import IconFactory from '../icons/factory-builder';
import colornames from '../../lib/colornames';
import { hasDataStore, getForm } from '../../form/local-storage/unregistered-questions-local-storage';
import { Recaptchav2, Recaptchav3 } from '../recaptcha';
import componentEnhance from '../../lib/component-enhance';
import PreventionFraudMessage from './questions-message-fraud';
import StaticPropsContext from '../context/static-props';
import { constants } from '../../utils/constants';
import { validateSnackbarColor, generateKeyToComponent } from '../../utils/validators';
import {
  validationsByDefault,
  emailValidations,
  hasError,
  hasValidations,
  getMaxLength,
} from '../../form/validations/questions-unregistered-validations';

const DEFAULT_DELAY_TIME = 3000;
const namespace = 'ui-pdp-questions-unregistered';
const { devices } = constants;
const { MOBILE } = devices;

// eslint-disable-next-line complexity
const QuestionsUnregistered = ({
  className,
  form,
  track,
  onCreateQuestion,
  url,
  messages,
  itemId,
  conditions,
  onSetFormErrors,
  key,
  recaptchaPublicKey,
  recaptchaSiteKey,
  onEnableStorageData,
  can_edit,
  isEditing,
  lgpd_confirmation,
  isFetchingData,
  onChange,
  focus,
  onRequestFocus,
  isModal,
}) => {
  const { name, last_name: lastName, email, phone, question, collapsible_info } = form;
  const { deviceType } = useContext(StaticPropsContext);
  const [recaptchaV2Token, setRecaptchaV2Token] = useState(null);
  const [lgpdConfirmationChecked, setLgpdConfirmationChecked] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isFocus, setIsFocus] = useState(focus);

  const ref = React.createRef();
  const inputFieldMap = {
    nameField: 'name',
    lastNameField: 'last_name',
    emailField: 'email',
    phoneField: 'phone',
    questionField: 'question',
  };
  const formInputValidationMap = {
    name: validationsByDefault,
    last_name: validationsByDefault,
    email: emailValidations,
    phone: validationsByDefault,
    question: validationsByDefault,
  };
  const hasErrorResult = hasError({ form, validations: formInputValidationMap });
  const hasValidationsError = hasValidations({ form });
  const hasDataStoreResult = hasDataStore(inputFieldMap);
  const isInternal = collapsible_info !== null && collapsible_info !== undefined;
  const canEdit = can_edit || !isInternal || (isInternal && !hasDataStoreResult);

  useEffect(() => {
    if (focus) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setTimeout(() => {
        setIsFocus(true);
      }, 500);
    }
  }, [focus, ref]);

  const getValue = (value, inputName) => {
    if (value || value === '') {
      return value;
    }
    return getForm(inputName, '');
  };

  const onInputChange = e => {
    if (onChange) {
      const { name: input, value } = e.target;
      onChange({ input, value });
    }
  };

  const maxLengthValues = {
    name: getMaxLength({ ...form.name }),
    lastName: getMaxLength({ ...form.lastname }),
    email: getMaxLength({ ...form.email }),
    question: getMaxLength({ ...form.question }),
  };

  const onBlur = e => {
    const { name: inputName } = e.target;
    const formInput = form[inputName];
    const currentValidationToApply = formInputValidationMap[inputName];
    form[inputName].error = currentValidationToApply({
      value: formInput.value,
      validations: form[inputName].validations,
    });
    form[inputName].has_validation_error = false;
    if (onRequestFocus) {
      setIsFocus(false);
      onRequestFocus({ focus: false });
    }
    return onSetFormErrors && onSetFormErrors({ form });
  };

  const handleOnSubmit = e => {
    e.preventDefault();

    const trimedValues = {
      ...form,
      name: { ...name, value: name.value.trim() },
      last_name: { ...lastName, value: lastName.value.trim() },
      email: { ...email, value: email.value.trim() },
      phone: { ...phone, value: phone.value.trim() },
      question: { ...question, value: question.value.trim() },
    };

    let phoneOutput = phone.value.trim();

    if (phoneOutput.length <= 0) {
      phoneOutput = null;
    }

    const outPutValues = {
      name: name.value.trim(),
      lastName: lastName.value.trim(),
      email: email.value.trim(),
      phone: phoneOutput,
      question: question.value.trim(),
    };

    return onCreateQuestion({
      action: 'unregistered_user_question',
      itemId,
      form: trimedValues,
      outPutValues,
      track,
      hasErrors: false,
      recaptchaV2Token,
      recaptchaSiteKey,
    });
  };

  const editData = e => {
    e.preventDefault();
    if (hasErrorResult) {
      const formInput = form[inputFieldMap.questionField];
      form[inputFieldMap.questionField].error = validationsByDefault({
        validations: formInput.validations,
        form: formInput.value,
      });
      onSetFormErrors({ form });
      return;
    }
    onEnableStorageData({ canEdit: true, isEditing: true });
  };

  const editQuestion = e => {
    e.preventDefault();
    if (hasErrorResult) {
      return;
    }
    onEnableStorageData({ canEdit: false, isEditing: false });
  };

  const getCollapsedDataClassName = additionalName =>
    classnames(`${namespace}__collapsed-data`, `${namespace}${additionalName}`);

  const lgpdConfirmationToggle = e => {
    setLgpdConfirmationChecked(e.target.checked);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setIsReady(true);
    if (hasValidationsError && !canEdit) {
      onEnableStorageData({ canEdit: true, isEditing: true });
    }
    form.name.value = getValue(form.name.value, inputFieldMap.nameField);
    form.last_name.value = getValue(form.last_name.value, inputFieldMap.lastNameField);
    form.email.value = getValue(form.email.value, inputFieldMap.emailField);
    form.phone.value = getValue(form.phone.value, inputFieldMap.phoneField);
    form.question.value = getValue(form.question.value, inputFieldMap.questionField);
  });

  return (
    <>
      <Recaptchav3 siteKey={recaptchaSiteKey} />
      {isReady && (
        <div className={classnames(namespace, className)} ref={ref}>
          {form.title && <h2 className={`${namespace}__title`}>{form.title.text}</h2>}
          {form.internal_subtitle && canEdit && (
            <Media className={classnames(`${namespace}__subtitle`)} title={form.internal_subtitle} />
          )}
          {form.subtitles.map(subtitle => (
            <Media
              key={generateKeyToComponent(subtitle)}
              className={classnames(`${namespace}__subtitle`, colornames(subtitle.icon))}
              title={subtitle.title}
              figure={IconFactory(subtitle.icon)}
            />
          ))}
          {form.message_prevention_fraud && <PreventionFraudMessage {...form.message_prevention_fraud} />}
          <AndesProvider>
            <Form
              className={classnames(`${namespace}__form`, { [`${namespace}__form--with-storage`]: hasDataStoreResult })}
              action={url}
              method="POST"
              onSubmit={handleOnSubmit}
            >
              <div className={`${namespace}__content`}>
                {(!isInternal || canEdit || hasValidationsError) && (
                  <>
                    <TextField
                      name={inputFieldMap.nameField}
                      data-testid="name-input"
                      className={`${namespace}__input`}
                      label={form.name.label}
                      labelFixed={form.name.label_fixed}
                      placeholder={form.name.placeholder}
                      autoComplete="off"
                      modifier={name.error ? 'error' : 'default'}
                      helper={name.error}
                      messageShow={!!name.error}
                      value={getValue(name.value, inputFieldMap.nameField)}
                      onChange={onInputChange}
                      onBlur={onBlur}
                      focus={isFocus}
                      maxLength={maxLengthValues.name}
                    />
                    <TextField
                      name={inputFieldMap.lastNameField}
                      data-testid="lastName-input"
                      className={`${namespace}__input`}
                      label={form.last_name.label}
                      labelFixed={form.last_name.label_fixed}
                      placeholder={form.last_name.placeholder}
                      autoComplete="off"
                      modifier={lastName.error ? 'error' : 'default'}
                      helper={lastName.error}
                      messageShow={!!lastName.error}
                      value={getValue(lastName.value, inputFieldMap.lastNameField)}
                      onChange={onInputChange}
                      onBlur={onBlur}
                      maxLength={maxLengthValues.lastName}
                    />
                    <TextField
                      name={inputFieldMap.emailField}
                      data-testid="email-input"
                      className={`${namespace}__input`}
                      label={form.email.label}
                      labelFixed={form.email.label_fixed}
                      placeholder={form.email.placeholder}
                      autoComplete="off"
                      type="email"
                      helper={email.error}
                      messageShow={!!email.error}
                      value={getValue(email.value, inputFieldMap.emailField)}
                      modifier={email.error ? 'error' : 'default'}
                      onChange={onInputChange}
                      onBlur={onBlur}
                      maxLength={maxLengthValues.email}
                    />
                    <TextField
                      name={inputFieldMap.phoneField}
                      data-testid="phone-input"
                      className={`${namespace}__input`}
                      label={form.phone.label}
                      labelFixed={form.phone.label_fixed}
                      placeholder={form.phone.placeholder}
                      autoComplete="off"
                      type="number"
                      value={getValue(phone.value, inputFieldMap.phoneField)}
                      onChange={onInputChange}
                      onBlur={onBlur}
                      helper={phone.error || form.phone.message}
                      messageShow={!!phone.error || !!form.phone.message}
                      modifier={phone.error ? 'error' : 'default'}
                    />
                  </>
                )}
                {!isEditing && (!hasValidationsError || !isModal) && (
                  <TextField
                    name={inputFieldMap.questionField}
                    data-testid="questions-input"
                    className={`${namespace}__input`}
                    placeholder={form.question.placeholder}
                    autoComplete="off"
                    multiline
                    helper={question.error || ''}
                    messageShow={!!question.error}
                    value={getValue(question.value, inputFieldMap.questionField)}
                    modifier={question.error ? 'error' : 'default'}
                    onChange={onInputChange}
                    onBlur={onBlur}
                    maxLength={maxLengthValues.question}
                  />
                )}
              </div>
              {(isEditing || hasValidationsError) && collapsible_info && (
                <div className={`${namespace}__data-store`}>
                  <>
                    <h3 className={`${namespace}__data-store__title`}>{form.question.label}</h3>
                    <div className={`${namespace}__data-store__container`}>
                      <p className={getCollapsedDataClassName('__question')}>
                        {getValue(question.value, inputFieldMap.questionField)}
                      </p>
                      <StyledLabel
                        as="a"
                        className={`${namespace}__data-store__edit`}
                        onClick={editQuestion}
                        text={collapsible_info.edit_link.text}
                        data-testid="edit_question_link"
                      />
                    </div>
                  </>
                </div>
              )}
              {hasDataStoreResult &&
                isInternal &&
                !canEdit &&
                !hasValidationsError &&
                getValue(name.value, inputFieldMap.nameField) && (
                  <div className={`${namespace}__data-store`}>
                    <>
                      <h3 className={`${namespace}__data-store__title`}>{collapsible_info.title.text}</h3>
                      <div className={`${namespace}__data-store__container`}>
                        <div className={`${namespace}__data-store__container-user-info`}>
                          <p className={getCollapsedDataClassName('__full-user-info')}>
                            {getValue(name.value, inputFieldMap.nameField).concat(
                              ' ',
                              getValue(lastName.value, inputFieldMap.lastNameField),
                            )}
                            <span className={getCollapsedDataClassName('__separator')}>|</span>
                            {getValue(email.value, inputFieldMap.emailField)}
                            {getValue(phone.value, inputFieldMap.phoneField) > 0 && (
                              <>
                                <span className={getCollapsedDataClassName('__separator')}>|</span>
                                {getValue(phone.value, inputFieldMap.phoneField)}
                              </>
                            )}
                          </p>
                        </div>
                        <StyledLabel
                          as="a"
                          className={`${namespace}__data-store__edit`}
                          onClick={editData}
                          text={collapsible_info.edit_link.text}
                          data-testid="edit_link"
                        />
                      </div>
                    </>
                  </div>
                )}
              {lgpd_confirmation && (
                <div className={`${namespace}__lgpd-confirmation-checkbox__wrapper`}>
                  <Checkbox
                    label={componentEnhance.jsx(lgpd_confirmation.text, lgpd_confirmation.values)}
                    className={`${namespace}__lgpd-confirmation-checkbox`}
                    onChange={lgpdConfirmationToggle}
                    checked={lgpdConfirmationChecked}
                  />
                </div>
              )}
              {recaptchaPublicKey && (
                <Recaptchav2
                  align="left"
                  className={`${namespace}-recaptcha`}
                  recaptchaPublicKey={recaptchaPublicKey}
                  required
                  lowEnd
                  id="unregistered-question-recaptcha"
                  verifyCallback={token => setRecaptchaV2Token(token)}
                />
              )}
              <div className={`${namespace}__footer`}>
                <Button
                  data-testid="questions-button"
                  className={`${namespace}__button`}
                  hierarchy="loud"
                  type="submit"
                  disabled={hasErrorResult || isFetchingData || !!(lgpd_confirmation && !lgpdConfirmationChecked)}
                  size="large"
                  fullWidth={deviceType === MOBILE}
                >
                  {form.confirm.label.text}
                </Button>
                <Media className={`${namespace}__conditions`} title={conditions} />
              </div>
            </Form>
          </AndesProvider>

          {messages && messages.text && (
            <Snackbar
              delay={DEFAULT_DELAY_TIME}
              message={messages.text}
              color={validateSnackbarColor(messages.type)}
              show
              key={key}
            />
          )}
        </div>
      )}
    </>
  );
};

QuestionsUnregistered.propTypes = {
  className: string,
  form: shape({
    title: shape({ text: string.isRequired }),
    internal_subtitle: shape({
      text: string.isRequired,
    }),
    subtitles: arrayOf(
      shape({
        text: string.isRequired,
        icon: shape({
          id: string,
          color: string,
        }),
      }),
    ),
    confirm: shape({
      label: shape({
        text: string.isRequired,
      }),
    }).isRequired,
    name: shape({
      label: string,
      label_fixed: bool,
      placeholder: string,
      message_show: bool,
      input_type: string,
      has_validation_error: bool,
      validations: arrayOf(
        shape({
          type: string,
          value: oneOfType([bool, number]),
          error_message: string,
        }),
      ),
    }),
    last_name: shape({
      label: string,
      label_fixed: bool,
      placeholder: string,
      message_show: bool,
      input_type: string,
      has_validation_error: bool,
      validations: arrayOf(
        shape({
          type: string,
          value: oneOfType([number, bool]),
          error_message: string,
        }),
      ),
    }),
    email: shape({
      label: string,
      label_fixed: bool,
      placeholder: string,
      message_show: bool,
      input_type: string,
      has_validation_error: bool,
      validations: arrayOf(
        shape({
          type: string,
          value: oneOfType([bool, number]),
          error_message: string,
        }),
      ),
    }),
    phone: shape({
      label: string,
      label_fixed: bool,
      placeholder: string,
      message_show: bool,
      input_type: string,
      has_validation_error: bool,
      validations: arrayOf(
        shape({
          type: string,
          value: string,
          error_message: string,
        }),
      ),
    }),
    question: shape({
      label: string,
      label_fixed: bool,
      placeholder: string,
      message_show: bool,
      input_type: string,
      has_validation_error: bool,
      validations: arrayOf(
        shape({
          type: string,
          value: oneOfType([bool, number]),
          error_message: string,
        }),
      ),
    }),
    message_prevention_fraud: shape({
      body: shape({
        text: string,
        values: shape({ bold_text: shape({ text: string, font_family: string }) }),
      }),
      closeable: bool,
      hierarchy: string,
      message_type: string,
    }),
  }).isRequired,
  onCreateQuestion: func.isRequired,
  onChange: func.isRequired,
  onEnableStorageData: func.isRequired,
  onSetFormErrors: func,
  url: string.isRequired,
  messages: shape({
    text: string,
    type: oneOf(['success', 'error']),
    form: shape({
      title: shape({ text: string.isRequired }),
      subtitles: arrayOf(
        shape({
          text: string.isRequired,
          icon: shape({
            id: string,
            color: string,
          }),
        }),
      ),
      conditions: shape({
        text: string.isRequired,
        values: shape(),
      }),
      confirm: shape({
        label: shape({
          text: string.isRequired,
        }),
        target: string.isRequired,
        item_id: string.isRequired,
      }).isRequired,
      name: shape({
        label: string,
        label_fixed: bool,
        placeholder: string,
        message_show: bool,
        input_type: string,
        has_validation_error: bool,
        validations: arrayOf(
          shape({
            type: string,
            value: string,
            error_message: string,
          }),
        ),
      }),
      last_name: shape({
        label: string,
        label_fixed: bool,
        placeholder: string,
        message_show: bool,
        input_type: string,
        has_validation_error: bool,
        validations: arrayOf(
          shape({
            type: string,
            value: string,
            error_message: string,
          }),
        ),
      }),
      email: shape({
        label: string,
        label_fixed: bool,
        placeholder: string,
        message_show: bool,
        input_type: string,
        has_validation_error: bool,
        validations: arrayOf(
          shape({
            type: string,
            value: string,
            error_message: string,
          }),
        ),
      }),
      phone: shape({
        label: string,
        label_fixed: bool,
        placeholder: string,
        message_show: bool,
        input_type: string,
        has_validation_error: bool,
        validations: arrayOf(
          shape({
            type: string,
            value: string,
            error_message: string,
          }),
        ),
      }),
      question: shape({
        label: string,
        label_fixed: bool,
        placeholder: string,
        message_show: bool,
        input_type: string,
        has_validation_error: bool,
        validations: arrayOf(
          shape({
            type: string,
            value: string,
            error_message: string,
          }),
        ),
      }),
    }),
  }),
  itemId: string.isRequired,
  conditions: shape({
    text: string.isRequired,
    values: shape(),
  }),
  key: string,
  track: shape({}).isRequired,
  collapsible_info: shape({
    title: shape({
      text: string.isRequired,
    }),
    edit_link: shape({
      text: string.isRequired,
    }),
  }),
  can_edit: bool,
  isEditing: bool,
  lgpd_confirmation: shape({
    text: string.isRequired,
    values: shape({}),
  }),
  recaptchaPublicKey: string,
  recaptchaSiteKey: string,
  focus: bool,
  onRequestFocus: func,
  isModal: bool,
  isFetchingData: bool,
};

QuestionsUnregistered.defaultProps = {
  className: null,
  messages: null,
  key: null,
  onSetFormErrors: null,
  conditions: null,
  can_edit: false,
  collapsible_info: null,
  isEditing: false,
  lgpd_confirmation: null,
  recaptchaPublicKey: null,
  recaptchaSiteKey: null,
  focus: false,
  onRequestFocus: null,
  isModal: false,
  isFetchingData: false,
};

export default React.memo(QuestionsUnregistered);
