import React, { useState, useContext } from 'react';
import { shape, func, string } from 'prop-types';
import { Modal } from '@andes/modal';
import StyledLabel from '../styled-label';
import SellerValidated from '../seller-validated';
import SellerProfileDesktop from '../seller-profile/seller-profile.desktop';
import StaticPropsContext from '../context/static-props';
import classnames from 'classnames';

const namespace = 'ui-pdp-seller-good-attention';

const SellerGoodAttentionLabel = ({ link_label, label, onScrollComponent, sellerProfile, className }) => {
  const [showModal, setShowModal] = useState(false);
  const { deviceType } = useContext(StaticPropsContext);
  const { show_track, ...sellerProfileProps } = sellerProfile ?? {};

  const onShowModal = () => setShowModal(true);

  return (
    <div className={classnames(className, `${namespace}`)}>
      {link_label && (
        <SellerValidated
          title={link_label.label}
          icon={link_label.icon}
          target={link_label.target}
          track={link_label.track}
          onScrollComponent={link_label.target ? onScrollComponent : onShowModal}
        />
      )}
      <StyledLabel {...label} className={`${namespace}__header`} />
      {sellerProfile && (
        <Modal
          className={classnames(className, `${namespace}__modal`)}
          open={showModal}
          onClose={() => setShowModal(false)}
          title=" "
          type={deviceType === 'mobile' ? 'full' : 'small'}
        >
          <SellerProfileDesktop className={className} {...sellerProfileProps} />
        </Modal>
      )}
    </div>
  );
};

SellerGoodAttentionLabel.propTypes = {
  className: string,
  link_label: shape({}),
  label: shape({}),
  onScrollComponent: func,
  sellerProfile: shape({}),
};

SellerGoodAttentionLabel.defaultProps = {
  className: '',
};

export default SellerGoodAttentionLabel;
